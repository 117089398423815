<template>
  <section :id="activeSection" class="relative -mt-28 h-auto pt-28">
    <div
      class="mx-auto -mt-8 mb-0 max-w-7xl border-papi-gold-200 px-4 py-16 sm:py-20 md:mb-16 md:mt-16 md:border lg:px-8"
    >
      <div class="mx-auto text-center">
        <h2
          class="font-dm-serif-display text-xl font-medium leading-7 tracking-wide text-papi-black-100 sm:text-3xl"
        >
          {{ getHeadingText(activeSection) }}
        </h2>
      </div>
      <div class="mt-10 bg-papi-gray-100 md:mt-20">
        <div class="mx-auto max-w-7xl p-6 sm:p-10">
          <dl class="space-y-4">
            <div v-for="method in currentMethods" :key="method.question">
              <dt class="text-base font-medium leading-7 text-papi-gray-300">
                {{ method.question }}
              </dt>
              <dd
                v-html="method.answer"
                class="text-base font-light leading-7 text-papi-gray-300"
              ></dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const methodsMap = {
  lashes_styling: [
    {
      id: 1,
      question: "",
      answer:
        "Początek to krótka konsultacja, podczas której omawiamy potrzeby i oczekiwania klientki:  preferencje dotyczące długości, objętości oraz stylu. Oczywiście dobieramy wszystko na podstawie ilości oraz kondycji rzęs naturalnych.",
    },
    {
      id: 2,
      question: "",
      answer:
        "Następnie oczyszczamy rzęsy klientki, usuwamy resztki makijażu i przechodzimy do procedury zabiegowej.",
    },
    {
      id: 3,
      question: "",
      answer:
        "Zabieg jest w pełni bezpieczny oraz bezbolesny. Czas zabiegu: od 1,5 do  3 h.",
    },
  ],
  non_standard_lashes_styling: [
    {
      id: 1,
      question: "Efekt Wet Look",
      answer:
        'Stylizacja rzęs "Wet Look" polega na nadaniu rzęsom mokrego i lśniącego wyglądu. W efekcie uzyskujemy nieregularne, gęste i postrzępione rzęsy. Wet Look to obecnie jedna z topowych stylizacji, które najchętniej wybierają klientki.',
    },
    {
      id: 2,
      question: "Color Lashes",
      answer:
        "Metody stylizacji rzęs nie ograniczają się jedynie do klasycznych czarnych lub brązowych rzęs. W ostatnich latach pojawiła się również moda na rzęsy kolorowe! Rzęsy mogą być dostępne w różnych kolorach, w tym niebieskim, różowym, fioletowym i wielu innych.",
    },
    {
      id: 3,
      question: "Efekt “Kim Kardashian”",
      answer:
        "Kim Kardashian to zdecydowanie spektakularna metoda stylizacji! Jest to symetryczne doklejanie słupków o 2mm dłuższych niż puszysta podstawa. Stylizację “Kim” możemy stosować przy dowolnej objętości, co zdecydowanie cieszy większość klientek!",
    },
    {
      id: 4,
      question: "Efekt “Kociego oka”",
      answer:
        "Kocie oko, czyli najbardziej przyciągająca oko stylizacja, która polega na znacznym wyciągnięciu kącika zewnętrznego. Ponad połowa rzęs jest krótka, a od połowy efekt zaczyna przechodzić w bardziej objętościowe i dłuższe rzęsy. Efektem stylizacji jest zalotne, naturalne i magnetyczne spojrzenie, które klientki często nazywają również “okiem Kleopatry”. Uwaga: ta metoda stylizacji nie na każdym oku daje pożądany efekt, dlatego przed aplikacją konieczna jest konsultacja.",
    },
    {
      id: 5,
      question: "Efekt Kim Camellia",
      answer:
        'Kim Camellia to stylizacja, w której dobierany jest mix długości od kącika wewnętrznego do kącika zewnętrznego tak, aby uzyskać efekt postrzępienia. Niepożądany jest w tej stylizacji efekt “od linijki".',
    },
  ],
  lashes_lamination: [
    {
      id: 1,
      question: "Etap 1 - Przygotowanie rzęs:",
      answer:
        "Na początku zabiegu rzęsy są dokładnie oczyszczone, usuwając z nich wszelkie pozostałości makijażu.",
    },
    {
      id: 2,
      question: "Etap 2 - Laminowanie:",
      answer:
        "Na rzęsy aplikowany jest specjalny preparat laminujący, który nadaje im pożądany kształt, a także unosi rzęsy. Preparat jest całkowicie bezpieczny dla oczu.",
    },
    {
      id: 3,
      question: "Etap 3 - Fixing (Utrwalanie):",
      answer:
        "Po laminowaniu na rzęsy nakłada się preparat utrwalający, który zapewnia trwałość efektu. Rzęsy są utrzymywane w odpowiednim położeniu, aż utrwalacz wyschnie.",
    },
  ],
  eyebrow_lamination: [
    {
      id: 1,
      question: "",
      answer:
        "Zabieg laminacji brwi jest stosunkowo prosty i składa się z kilku etapów:",
    },
    {
      id: 2,
      question: "1. Przygotowanie brwi:",
      answer:
        "Włoski są dokładnie oczyszczane i przygotowywane do zabiegu. Usuwane są resztki makijażu, aby preparaty mogły zadziałać w pełni skutecznie.",
    },
    {
      id: 3,
      question: "2. Laminowanie:",
      answer:
        "Następnie na włoski nakładany jest preparat laminujący, który nadaje im odpowiedni kształt i ułatwia stylizację. Preparat jest bezpieczny i łagodny dla skóry oraz włosków.",
    },
    {
      id: 4,
      question: "3. Fixing (Utrwalanie):",
      answer:
        "Po laminowaniu nakładany jest profesjonalny preparat, który utrwala kształt włosków. Brwi są utrzymywane w odpowiednim położeniu, aż utrwalacz wyschnie.",
    },
    {
      id: 5,
      question: "4. Opcjonalne malowanie brwi:",
      answer:
        "Jeśli klientka tego życzy, można również pomalować brwi podczas zabiegu, nadając im kolor i intensywność.",
    },
  ],
  classic_henna: [
    {
      id: 1,
      question: "Naturalny wygląd.",
      answer:
        "Henna klasyczna daje brwiom naturalny wygląd. Efekt jest subtelny i dostosowany do indywidualnych preferencji klientki.",
    },
    {
      id: 2,
      question: "Wyrazistość.",
      answer:
        "Zabieg podkreśla kontury brwi, nadając im wyrazistości, dzięki czemu brwi zyskują perfekcyjny kształt. ",
    },
    {
      id: 3,
      question: "Trwałość.",
      answer:
        "Efekt utrzymuje się znacznie dłużej niż po zwykłym malowaniu brwi np. kredką, czy pomadą.",
    },
    {
      id: 4,
      question: "Bezpieczeństwo składników.",
      answer:
        "Henna klasyczna to naturalny produkt pozyskiwany z liści krzewu henny (Lawsonia inermis). Nie zawiera ostrych  chemikaliów ani substancji drażniących skórę.",
    },
  ],
  powder_henna: [
    {
      id: 1,
      question: "1. Perfekcyjny kształt brwi.",
      answer:
        "Geometria umożliwia uzyskanie perfekcyjnego kształtu, który pasuje do proporcji twarzy klientki. Brwi wyglądają proporcjonalnie i estetycznie, dodając twarzy uroku.",
    },
    {
      id: 2,
      question: "2. Naturalny wygląd.",
      answer:
        "Mimo intensywności koloru henny pudrowej brwi nadal wyglądają naturalnie.",
    },
    {
      id: 3,
      question: "3. Dopasowany kolor.",
      answer:
        "Podczas zabiegu kolor dopasowany jest indywidualnie do urody i preferencji klientki.",
    },
  ],
  eyebrow_makeup: [
    {
      id: 1,
      question: "Etap 1: Konsultacja oraz dobór kształtu i koloru",
      answer:
        "Jest to bardzo ważny etap, ponieważ właśnie wtedy omawiamy oczekiwania i obawy klienta.",
    },
    {
      id: 2,
      question: "Etap 2: Przygotowanie brwi i skóry",
      answer:
        "Brwi są dokładnie oczyszczane z makijażu i innych produktów. Następnie przystępujemy do dezynfekcji miejsca zabiegowego.",
    },
    {
      id: 3,
      question: "Etap 3: Aplikacja Pigmentu",
      answer:
        "Pigment jest starannie aplikowany pod skórę za pomocą specjalnego urządzenia oraz igły zabiegowej.",
    },
    {
      id: 4,
      question: "Etap 4: Omówienie efektu końcowego",
      answer:
        "Podczas tego etapu sprawdzamy, czy wszystko wyszło dokładnie tak, jak było zaplanowane, a także omawiamy  zalecenia pozabiegowe.",
    },
    {
      id: 5,
      question: "Etap 5: Ustalenie terminu na zabieg korygujący",
      answer:
        "Po pełnym wygojeniu spotykamy się ponownie na kontroli przyjęcia się pigmentu i ewentualnym dopigmentowaniu. Szacowany czas: 4-5 tygodni po zabiegu.",
    },
  ],
  lip_makeup: [
    {
      id: 1,
      question: "Etap pierwszy:",
      answer:
        "Procedurę zabiegową zaczynamy od oczyszczenia ust i wykonania rysunku wstępnego. Po zatwierdzeniu i ustaleniu koloru przechodzimy do zabiegu właściwego tj. pigmentacji.",
    },
    {
      id: 2,
      question: "Etap drugi:",
      answer: "Pigment wprowadzany jest pod skórę za pomocą cieniutkiej igły.",
    },
    {
      id: 3,
      question: "Etap końcowy:",
      answer:
        "Po zakończonym zabiegu usta zostają zabezpieczone, a klientka otrzymuje zalecenia dotyczące postępowania pozabiegowego.",
    },
  ],
  eyeliner_makeup: [
    {
      id: 1,
      question: "",
      answer:
        "Makijaż permanentny kreski na powiece to zabieg polegający na wprowadzeniu za pomocą cieniutkiej igły pigmentu pod skórę w celu trwałego podkreślenia kształtu i konturu oczu. W przeciwieństwie do tradycyjnego eyelinera, który trzeba codziennie nakładać i zmywać.  Makijaż permanentny zapewnia efekt, który utrzymuje się przez wiele miesięcy lub lat, w zależności od rodzaju skóry i pigmentów użytych do zabiegu.",
    },
  ],
  makeup_removal: [
    {
      id: 1,
      question: "",
      answer:
        "Usuwanie makijażu permanentnego to zabieg, który polega na bezpiecznym pozbywaniu się pigmentu z warstwy skóry właściwej. Dostępne metody usunięcia makijażu w naszym gabinecie to obecnie:",
    },
    {
      id: 2,
      question: "Laser:",
      answer:
        "Laseroterapia wykorzystuje energię lasera do rozbicia pigmentu na mniejsze cząsteczki, które zostają wydalone przez nasz organizm. Ilość zabiegów ustalana jest indywidualnie i zależy od takich czynników jak: głębokość barwnika, kolor pigmentu i  stan skóry pacjenta. Zabiegi przy użyciu lasera są skuteczne i całkowicie bezpieczne.",
    },
    {
      id: 3,
      question: "Remover:",
      answer:
        "Zabieg przy użyciu Removera przebiega podobnie, jak zabieg wykonania makijażu permanentnego. Preparat (Remover) wprowadza się do skóry za pomocą igły oraz maszynki do mikropigmentacji, co w efekcie pozwala na wyciągnięcie barwnika na powierzchnię skóry. Znaczna część pigmentu zostaje usunięta już podczas zabiegu, pozostałości pigmentu usuwają się samoistnie podczas procesu regeneracji skóry.",
    },
    {
      id: 4,
      question: "",
      answer:
        "Jeśli makijaż permanentny jest źródłem Twoich  kompleksów lub dyskomfortu, usunięcie go może znacząco poprawić Twoje samopoczucie i pewność siebie. ",
    },
  ],
  laser_remover: [
    {
      id: 1,
      question: "Laserowy zabieg:",
      answer:
        "Etap 1: Skóra jest dokładnie oczyszczana, a obszar poddawany zabiegowi jest znieczulany, aby zminimalizować ból i dyskomfort.",
    },
    {
      id: 2,
      question: "",
      answer:
        "Etap 2: Zabieg właściwy – zaczynamy pracę laserem, który emituje impulsy światła. Podczas zabiegu energia lasera rozbija cząsteczki pigmentu na mniejsze fragmenty, które nasz organizm metabolizuje.",
    },
    {
      id: 3,
      question: "",
      answer:
        "Etap 3: Po zabiegu skóra potrzebuje czasu na gojenie się. W międzyczasie pigment zaczyna stopniowo znikać.<br /><br />",
    },
    {
      id: 4,
      question: "Zabieg Removerem:",
      answer:
        "Podczas zabiegu z użyciem Removeru duża ilość pigmentu zostaje usunięta już podczas pierwszego zabiegu, a pozostałości barwnika zostają wydalone podczas procesu gojenia się skóry.",
    },
    {
      id: 5,
      question: "",
      answer:
        "Etap 1: Skóra jest dokładnie oczyszczana, a obszar poddawany zabiegowi jest znieczulany, aby zminimalizować ból i dyskomfort.",
    },
    {
      id: 5,
      question: "",
      answer:
        "Etap 2: Zabieg właściwy – zaczynamy pracę Removerem, który wnika w powierzchniowe warstwy skóry i usuwa pigment.",
    },
    {
      id: 6,
      question: "",
      answer: "Etap 3: Po zabiegu skóra potrzebuje czasu na gojenie się.",
    },
  ],
  detox_peel: [
    {
      id: 1,
      question: "",
      answer:
        "Skóra po zabiegu staje się promienna, pozbawiona toksyn, a także widocznie jaśniejsza. Zredukowane zostają przebarwienia, blizny oraz głębokie zmarszczki. ",
    },
  ],
  chemical_peeling: [
    {
      id: 1,
      question: "",
      answer:
        "Peelingi chemiczne, które polegają na aplikacji specjalnych substancji chemicznych na skórę w celu usunięcia martwych komórek naskórka oraz pobudzenia regeneracji skóry. Istnieje wiele różnych rodzajów peelingów chemicznych, które różnią się rodzajem i stężeniem używanych substancji chemicznych. Najczęściej stosowanymi substancjami w peelingach chemicznych są kwasy owocowe (np. kwas glikolowy, kwas mlekowy), kwasy salicylowe, kwasy trójchlorooctowy (TCA) i fenol.<br /><br /> Zabieg peelingu chemicznego polega na nałożeniu odpowiedniej substancji na skórę, która rozpuszcza martwe komórki naskórka. Dzięki temu dochodzi do złuszczenia górnych warstw naskórka, co odsłania nową, zdrowszą skórę.",
    },
  ],
  mesotherapy: [
    {
      id: 1,
      question: "",
      answer: `Mezoterapia igłowa polega na wprowadzaniu w głąb skóry za pomocą cieniutkich igieł, niewielkich dawek substancji leczniczych – kwasu hialuronowego, mikroelementów oraz witamin. Skład zastosowanego preparatu uzależniony jest od indywidualnych potrzeb i oczekiwań, a także rodzaju zabiegu.`,
    },
    {
      id: 2,
      question: "",
      answer: `Po zabiegu na obszarze zabiegowym mogą pojawić się niewielkie siniaki, które są naturalnym skutkiem ubocznym nakłuć - nie przejmuj się - znikną w ciągu kilku dni.`,
    },
  ],
  tissue_stimulator: [
    {
      id: 1,
      question: "",
      answer: `Stymulatory tkankowe, nazywane również bio-stymulatorami, to preparaty, które zawierają substancje stymulujące produkcję kolagenu i elastyny w skórze, czyli tzw. białka młodości. Zarówno kolagen, jak i elastyna są <span class="font-medium">kluczowe dla sprężystości, elastyczności oraz jędrności naszej skóry.</span> W przeciwieństwie do wypełniaczy, które wypełniają stricte ubytki w skórze, <span class="font-medium">stymulatory tkankowe wpływają na strukturę skóry, wzmacniając i regenerując ją od wewnątrz.</span>`,
    },
    {
      id: 2,
      question: "",
      answer: `Podczas zabiegu, wstrzykiwana jest odpowiednia ilość preparatu stymulującego w określone miejsca zabiegowe, czego efektem jest <span class="font-medium">wzmocnienie i zagęszczenie skóry, przywrócenie skórze napięcia, wyostrzenie linii żuchwy, lifting oraz wypełnienie i ujędrnienie zmarszczek.</span>`,
    },
    {
      id: 3,
      question: "",
      answer: `Jeśli zabieg stosowany jest poza obszarem twarzy np. w obrębie ramion, brzucha, czy też wewnętrznej części ud - również uzyskujemy efekt napiętej, jędrnej oraz wyraźnie zdrowszej skóry.`,
    },
  ],
  lip_modeling: [
    {
      id: 1,
      question: "",
      answer: `Metoda modelowania ust na płasko powstała jako reakcja na tzw. kacze usta. Celem zabiegu jest precyzyjne wymodelowanie górnej wargi oraz podkreślenie łuku kupidyna, ale bez zbyt dużego uwypuklenia, czyli dzióbka, który sprawia, że usta wyglądają nienaturalnie.`,
    },
    {
      id: 2,
      question: "",
      answer: `Dolna warga natomiast modelowana jest minimalnie. W efekcie usta przed i po zabiegu mają zupełnie inne proporcje.`,
    },
    {
      id: 3,
      question: "",
      answer: `Zabieg modelowania ust polega na wprowadzeniu kwasu hialuronowego igłą. Na usta wcześniej nakładamy maść znieczulającą. Dzięki temu miejsca wkłuć będą praktycznie nieodczuwalne lub dyskomfort będzie jedynie w  minimalnym stopniu.`,
    },
  ],
  lipolysis: [
    {
      id: 1,
      question: "",
      answer: `Lipoliza iniekcyjna  to zabieg polegający na wstrzykiwaniu za pomocą cienkich igieł specjalnych substancji, zwanych lipolitykami, bezpośrednio do obszarów objętych nadmierną tkanką tłuszczową.`,
    },
    {
      id: 2,
      question: "",
      answer: `Wstrzyknięte substancje działają na komórki tłuszczowe, rozkładając i rozbijając je na mniejsze cząsteczki, które następnie są metabolizowane przez organizm. Najczęściej używaną substancją w lipolizie iniekcyjnej jest deoksycholan sodu.`,
    },
    {
      id: 3,
      question: "",
      answer: `Po zabiegu w miejscach nakłuć mogą występować drobne krwiaki, które są odpowiedzią organizmu na iniekcje. Jest to całkowicie naturalny objaw, który znika po kilku dniach od zabiegu.`,
    },
  ],
  botulinum_toxin: [
    {
      id: 1,
      question: "",
      answer: `Zabieg polega na wstrzyknięciu niewielkiej ilości toksyny botulinowej w określone miejsca na twarzy, które chcemy poddać kuracji anti-aging. Lekarz za pomocą cienkiej igły, wprowadza toksynę, która blokuje impulsy nerwowe do mięśni, co powoduje ich odprężenie oraz rozluźnienie. <span class="font-medium">W efekcie zmarszczki mimiczne stają się mniej mniej widoczne lub znikają całkowicie, a skóra staje się jędrniejsza i gładsza.</span>`,
    },
  ],
};

export default {
  name: "TreatmentMoreInfo",
  computed: {
    activeSection() {
      return this.$store.state.activeSection;
    },
    currentMethods() {
      return methodsMap[this.activeSection] || [];
    },
  },
  methods: {
    getHeadingText(section) {
      const headingTextMap = {
        lashes_styling: "Jak wygląda zabieg stylizacji i przedłużania rzęs",
        non_standard_lashes_styling:
          "Jakie są niestandardowe metody stylizacji rzęs",
        lashes_lamination: "Jak wygląda zabieg laminacji rzęs",
        eyebrow_lamination: "Jak wygląda zabieg laminacji brwi",
        classic_henna: "Zalety henny klasycznej brwi",
        powder_henna: "Zalety henny pudrowej brwi",
        eyebrow_makeup: "Jak wygląda zabieg makijażu permanentnego brwi",
        lip_makeup: "Jak wygląda zabieg makijażu permanentnego ust",
        eyeliner_makeup: "Jak wygląda zabieg kreski permanentnej na powiece",
        makeup_removal: "Jak wygląda zabieg usuwania makijażu permanentnego",
        laser_remover:
          "Jak wyglądają zabiegi usuwania makijażu permanentnego Laserem i Removerem",
        detox_peel: "Jakie efekty daje Detox Peel",
        chemical_peeling: "Jak działają peelingi chemiczne",
        mesotherapy: "Na czym polega zabieg mezoterapii igłowej",
        tissue_stimulator: "Jak działają stymulatory tkankowe",
        lip_modeling: "Jak wygląda zabieg modelowania ust na płasko",
        lipolysis: "Jak wygląda zabieg lipolizy iniekcyjnej",
        botulinum_toxin: "Jak działa botoks",
      };
      return headingTextMap[section] || "";
    },
  },
  setup() {
    return {
      methodsMap,
    };
  },
};
</script>

<style scoped></style>
