<template>
  <main
    class="relative flex-wrap bg-white lg:bg-gradient-to-r lg:from-gray-800 lg:to-white"
  >
    <img
      class="absolute inset-0 hidden h-full w-full object-cover md:block xl:mix-blend-hard-light"
      src="@/assets/papi_large.png"
      alt="papi_large"
    />
    <div class="mb-8 mt-12 flex justify-center md:hidden">
      <img
        class="h-24 flex-shrink-0"
        src="../assets/papi_logo_horizontal.png"
        alt="papi_logo_horizontal"
      />
    </div>
    <div class="block md:hidden">
      <img
        class="relative w-full object-cover"
        src="@/assets/papi_mobile.jpeg"
        alt="papi_mobile"
      />
    </div>

    <div class="flex justify-start md:justify-end">
      <div
        class="relative w-full px-4 pb-8 pt-8 sm:px-6 sm:pb-32 md:w-2/5 md:pt-72 md:px-10"
      >
        <div class="hidden md:ml-2 md:flex md:justify-start">
          <img
            class="h-32 flex-shrink-0 sm:-ml-4 sm:h-40 lg:h-52"
            src="../assets/papi_logo_horizontal.png"
            alt="papi_logo_horizontal"
          />
        </div>
        <h2
          class="text-base font-medium text-papi-black-100 sm:mt-10 md:mt-12 md:text-lg"
        >
          Nazywam się Paulina Kowalewska i zapraszam Cię do mojego Świata.
        </h2>
        <p
          class="mt-3 max-w-3xl text-base font-light text-papi-black-100 sm:mt-6 md:text-lg"
        >
          Pierwotnie Pracownia Rzęs PaPi powstała z miłości do rzęs.
        </p>
        <p
          class="mt-3 max-w-3xl text-base font-light text-papi-black-100 sm:mt-6 md:text-lg"
        >
          Pięknie wystylizowane oko odgrywa ważną kwestię w naszym wizerunku,
          oszczędza czas spędzony przed lustrem, ale przede wszystkim wpłwa
          pozytywnie na samopoczucie.
        </p>
        <p
          class="mt-3 max-w-3xl text-base font-light text-papi-black-100 sm:mt-6 md:text-lg"
        >
          Uśmiech jest podstawą udanego dnia!
        </p>
        <p
          class="mt-3 max-w-3xl text-base font-light text-papi-black-100 sm:mt-6 md:text-lg"
        >
          Dziś tworzę <span class="font-medium">Pracownię PaPi</span>.
        </p>
        <p
          class="mt-3 max-w-3xl text-base font-light text-papi-black-100 sm:mt-6 md:text-lg"
        >
          Wychodząc na przeciw Waszym potrzebom, znacznie rozbudowaliśmy naszą
          ofertę.
        </p>
        <p
          class="mt-3 max-w-3xl text-base font-light text-papi-black-100 sm:mt-6 md:text-lg"
        >
          Motto: „Bądź piękna każdego dnia...” nie jest przypadkowe.
        </p>
        <p
          class="mt-3 max-w-3xl text-base font-light text-papi-black-100 sm:mt-6 md:text-lg"
        >
          Przeprowadzamy zabiegi, które przedłużają młodość i podkreślają Wasze
          naturalne piękno 🤍
        </p>
        <p
          class="mt-3 max-w-3xl text-base font-light text-papi-black-100 sm:mt-6 md:text-lg"
        >
          Zapraszam Cię do mojej Pracowni.
          <br />PaPi
        </p>
        <div class="mt-8 sm:flex sm:justify-center md:mt-10 lg:justify-start">
          <div class="flex justify-center">
            <a
              href="#contact"
              type="button"
              class="md:w-26 flex w-24 items-center justify-center rounded-sm border border-papi-gold-200 bg-papi-gold-200 px-8 py-2 text-base font-normal text-white shadow transition duration-150 ease-in-out hover:bg-transparent hover:text-papi-gold-200 md:w-full md:py-2.5 md:text-lg"
            >
              Kontakt
            </a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>
