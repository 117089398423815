<template>
  <section :id="activeSection" class="relative -mt-28 h-full pt-28">
    <div
      class="mx-auto mb-16 max-w-7xl border-papi-gold-200 px-4 py-0 sm:mt-16 sm:py-20 md:mt-0 md:border lg:px-8"
    >
      <div class="mx-auto text-center">
        <h2
          class="font-dm-serif-display text-xl font-normal tracking-wide text-papi-black-100 sm:text-3xl"
        >
          O zabiegu
        </h2>
      </div>
      <div class="mt-10 md:mt-20">
        <dl
          class="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-24"
        >
          <div v-for="faq in currentMethods" :key="faq.id">
            <dt
              class="text-base font-medium leading-7 text-papi-black-100 sm:text-lg"
            >
              {{ faq.question }}
            </dt>
            <dd
              class="mt-2 text-base font-light leading-7 text-papi-gray-300"
              v-html="faq.answer"
            ></dd>
          </div>
        </dl>
      </div>
      <div
        v-if="!shouldRenderForNonStandardLashesStyling()"
        class="mt-16 bg-papi-gray-100 font-medium md:mt-20"
      >
        <div class="mx-auto max-w-7xl p-6 sm:p-12">
          <h2
            role="list"
            class="text-lg font-medium text-papi-black-100 sm:mb-8 sm:text-center sm:text-xxl sm:leading-10"
          >
            Przeciwwskazania do zabiegu
          </h2>
          <p class="my-4 text-base font-light leading-7 text-papi-gray-300">
            {{ getContraindicationIntro(activeSection) }}
          </p>
          <dl
            v-for="contraindication in currentContraindications"
            :key="contraindication.id"
            class="mt-4 grid grid-cols-1 gap-y-3 md:gap-y-2"
          >
            <li
              class="flex items-baseline gap-x-3 text-base font-light leading-7 text-papi-gray-300"
            >
              <i class="fa-regular fa-circle-xmark text-papi-gold-200" />
              <span v-html="contraindication.text" />
            </li>
          </dl>
          <div v-if="shouldRenderForLaserRemover()">
            <p class="my-4 text-base font-light leading-7 text-papi-gray-300">
              Przeciwwskazania do usuwania makijażu permanentnego Removerem:
            </p>
            <ul class="mt-4 grid grid-cols-1 gap-y-3 md:gap-y-2">
              <li
                class="flex items-baseline gap-x-3 text-base font-light leading-7 text-papi-gray-300"
              >
                <i class="fa-regular fa-circle-xmark text-papi-gold-200" />
                <span>Ciąża i okres karmienia piersią</span>
              </li>
              <li
                class="flex items-baseline gap-x-3 text-base font-light leading-7 text-papi-gray-300"
              >
                <i class="fa-regular fa-circle-xmark text-papi-gold-200" />
                <span>Epilepsja</span>
              </li>
              <li
                class="flex items-baseline gap-x-3 text-base font-light leading-7 text-papi-gray-300"
              >
                <i class="fa-regular fa-circle-xmark text-papi-gold-200" />
                <span>Aktywne infekcje oraz choroby</span>
              </li>
              <li
                class="flex items-baseline gap-x-3 text-base font-light leading-7 text-papi-gray-300"
              >
                <i class="fa-regular fa-circle-xmark text-papi-gold-200" />
                <span>Cukrzyca</span>
              </li>
              <li
                class="flex items-baseline gap-x-3 text-base font-light leading-7 text-papi-gray-300"
              >
                <i class="fa-regular fa-circle-xmark text-papi-gold-200" />
                <span>Aktywne choroby skóry</span>
              </li>
              <li
                class="flex items-baseline gap-x-3 text-base font-light leading-7 text-papi-gray-300"
              >
                <i class="fa-regular fa-circle-xmark text-papi-gold-200" />
                <span>Choroby nowotworowe</span>
              </li>
              <li
                class="flex items-baseline gap-x-3 text-base font-light leading-7 text-papi-gray-300"
              >
                <i class="fa-regular fa-circle-xmark text-papi-gold-200" />
                <span>Radioterapia i chemioterapia</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const faqsMap = {
  lashes_styling: [
    {
      id: 1,
      question: "Efekty przedłużania i stylizacji rzęs?",
      answer: `<ul>
          <li class="mt-2">
            <span class="font-medium">
              1. Wzmocnienie i poprawa kondycji rzęs naturalnych:
            </span>
            Stylizacja rzęs może pomóc we wzmocnieniu i zregenerowaniu rzęs, co jest korzystne szczególnie 	dla osób, które mają rzęsy osłabione lub uszkodzone przez codzienny makijaż, czy używanie zalotki.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              2. Naturalnie piękny wygląd:
            </span>
            Dzięki stylizacji rzęs można osiągnąć efekt naturalnego 	podkreślenia oczu, bez konieczności codziennego stosowania maskary.
          </li>
          <li class="mt-6">
            <span class="font-medium">3. Długotrwały efekt:</span> Po stylizacji rzęs można cieszyć się efektem przez wiele tygodni, co oszczędza czas podczas wykonywania codziennego makijażu.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Jak często wykonywać korektę rzęs?",
      answer:
        "Cykl wzrostu naszych rzęs wynosi zazwyczaj od 4 do 6 tygodni. Ponieważ część naturalnych rzęs, do których zostały przyklejone rzęsy sztuczne, jest starsza – aby utrzymać pełny efekt stylizacji, zaleca się odświeżanie co 3-4 tygodnie. Niektóre osoby przez bardzo szybki wzrost rzęs naturalnych, uzupełniają stylizację co 2 tygodnie.",
    },
    {
      id: 3,
      question: "Czy zabieg przedłużania niszczy naturalne rzęsy?",
      answer: "Prawidłowo wykonane przedłużanie nie niszczy naturalnych rzęs.",
    },
  ],
  non_standard_lashes_styling: [
    {
      id: 1,
      question: "Jaka metoda stylizacji rzęs jest najlepsza?",
      answer:
        "Wybór odpowiedniej metody stylizacji rzęs zależy od indywidualnych preferencji i kondycji rzęs naturalnych. Bez względu na to, czy pragniesz dłuższych, gęstszych rzęs, czy efektu wow - z pewnością istnieje metoda stylizacji, która spełni Twoje oczekiwania. Przed przystąpieniem do zabiegu omawiamy możliwości i dobieramy odpowiednią stylizację.",
    },
  ],
  lashes_lamination: [
    {
      id: 1,
      question: "Dla kogo przeznaczony jest zabieg laminacji rzęs?",
      answer: `
        <ul>
          <li>
            <span class="font-medium">
              Dla osób o prostych lub opadających rzęsach:
            </span>
            Zadaniem tego zabiegu jest podkręcenie rzęs naturalnych.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Dla osób, które borykają się z krótkimi lub rzadkimi rzęsami:
            </span>
            Laminacja rzęs może optycznie wydłużyć rzęsy i nadać im objętości.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Dla osób, których rzęsy są mocno wymagające:
            </span>
            Jeśli Twoje rzęsy są trudne do ujarzmienia i wymagają codziennego
            używania zalotki i tuszu do rzęs, laminacja rzęs może ułatwić ich
            stylizację.
          </li>
          <li class="mt-6">
            <span class="font-medium">Dla osób aktywnych fizycznie:</span> Dla
            osób, które uprawiają sporty, laminacja rzęs może być doskonałym
            rozwiązaniem, ponieważ pozwala zachować efekt wyrazistych rzęs bez
            potrzeby stosowania kosmetyków utrwalających spojrzenie
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Dla osób, które chcą coś zmienić w swoim wyglądzie:
            </span>
            Laminacja rzęs może dodać wyrazistości spojrzeniu, nawet jeśli
            preferujesz naturalny makijaż i nie chcesz stosować innych
            kosmetyków do oczu.
          </li>
        </ul>
      `,
    },
    {
      id: 2,
      question: "Efekty laminacji rzęs?",
      answer: `
        <ul>
          <li>
            Po zabiegu laminacji rzęs efekty widoczne są gołym okiem:
          </li>
          <li class="my-4">
            1. Rzęsy stają się bardziej widoczne i pełniejsze.
          </li>
          <li>
            2. Efekt utrzymuje się przez wiele tygodni, aż nowe rzęsy naturalne urosną i wymagają poprawy.
          </li>
        </ul>
      `,
    },
  ],
  eyebrow_lamination: [
    {
      id: 1,
      question: "Zalety laminacji brwi?",
      answer: `<ul>
          <li>
            Laminacja brwi to zabieg, który może przynieść wiele korzyści, zarówno dla osób, które mają gęste i bujne brwi, jak i dla tych, które borykają się z problemem cienkich i niezdyscyplinowanych włosków. Jakie efekty daje zabieg laminacji?
          </li>
          <li class="mt-6">
            <span class="font-medium">
              1. Wyraziste brwi:
            </span>
            Laminacja brwi nadaje włoskom odpowiedni kształt, co sprawia, że brwi wyglądają na bardziej wyraziste i schludne.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              2. Łatwość domowej stylizacji:
            </span>
            Po laminacji brwi łatwiej jest utrzymać je w odpowiednim kształcie i zapanować nad niezdyscyplinowanymi włoskami, co eliminuje konieczność codziennego używania żelu czy wosku do brwi.
          </li>
          <li class="mt-6">
            <span class="font-medium">3. Naturalny wygląd brwi:</span> Zabieg dąży do zachowania naturalnego wyglądu i pożądanego kształtu brwi.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              4. Trwały efekt:
            </span>
            Efekt laminacji brwi utrzymuje się przez kilka tygodni, dzięki czemu można cieszyć się pięknymi brwiami bez konieczności częstej pielęgnacji. Pozwala to również na zaoszczędzenie czasu podczas porannego makijażu.
          </li>
           <li class="mt-6">
            <span class="font-medium">
              5. Korekta kształtu:
            </span>
            Zabieg umożliwia delikatną korektę kształtu brwi, dostosowując go do indywidualnych preferencji klientki. Kształt brwi ma duży wpływ na proporcję twarzy, a także mimikę, dlatego uzyskany efekt laminacji powinien być dostosowany indywidualnie do preferencji klientki.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Jakie efekty daje laminacja brwi?",
      answer: `<ul>
          <li>
            Po zabiegu laminacji brwi można oczekiwać kilku efektów:
          </li>
          <li class="mt-6">
            1. Brwi są uniesione, podniesione i wyprostowane, co nadaje twarzy wyrazistości, a także optycznie otwiera spojrzenie.
          </li>
          <li class="mt-6">
            2. Włoski stają się bardziej “posłuszne” i ukształtowane.
          </li>
          <li class="mt-6">
            3. Efekt utrzymuje się przez kilka tygodni, aż nowe włoski urosną i wymagają ponownego zabiegu.
          </li>
        </ul>`,
    },
  ],
  classic_henna: [
    {
      id: 1,
      question: "Dla kogo jest dedykowana henna klasyczna brwi?",
      answer: `<ul>
          <li class="mt-2">
           - Dla osób, które posiadają ledwo widoczne brwi
          </li>
          <li class="mt-2">
            - Dla posiadaczek jasnej oprawy oczu
          </li>
           <li class="mt-2">
           - Dla kobiet, które mają alergię na kosmetyki drogeryjne przeznaczone do stylizacji oraz barwienia brwi
          </li>
          <li class="mt-2">
            - Dla osób, które obawiają się makijażu permanentnego brwi
          </li>
           <li class="mt-2">
           - Dla kobiet, które często mają kontakt z wodą (np. osoby pracujące na basenie)
          </li>
        </ul>`,
    },
  ],
  powder_henna: [
    {
      id: 1,
      question: "Dla kogo jest dedykowana henna pudrowa?",
      answer: `<ul>
          <li class="mt-2">
           - Dla osób, których brwi mają nieregularny kształt lub rosną nierównomiernie. Henna pudrowa ułatwia “ujarzmienie” niesfornych włosków
          </li>
          <li class="mt-2">
            - Dla osób o cienkich lub rzadkich brwiach. Henna pudrowa z geometrią może dodać objętości i intensywności
          </li>
           <li class="mt-2">
           - Dla osób o jasnym odcieniu brwi. Henna pudrowa nada brwiom głębszy i wyraźniejszy kolor
          </li>
          <li class="mt-2">
            - Dla osób poszukujących trwalszego efektu. Henna pudrowa z geometrią może zapewnia trwały efekt przez wiele tygodni
          </li>
        </ul>`,
    },
  ],
  eyebrow_makeup: [
    {
      id: 1,
      question: "Dla kogo przeznaczony jest makijaż permanentny brwi?",
      answer: `<ul>
          <li class="mt-2">
           - Dla osób, które na co dzień borykają się z cienkimi, rzadkimi brwiami lub gdy występują ubytki włosków. Makijaż permanentny brwi może dodać objętości i intensywności.
          </li>
          <li class="mt-4">
            - Dla osób o bardzo jasnym włosie. Makijaż permanentny może nadać brwiom głębszy kolor i wyrazistość, podkreślając tym samym rysy twarzy.
          </li>
          <li class="mt-4">
            - Dla osób pragnących długotrwałego efektu. Dla tych, którzy nie chcą codziennie rysować brwi.
          </li>
          <li class="mt-4">
            - Dla osób o nieregularnym wzroście włosa na brwiach. Makijaż permanentny może wizualnie poprawić ich wygląd. 
          </li>
        </ul>`,
    },
  ],
  lip_makeup: [
    {
      id: 1,
      question: "Czy zabieg makijażu permanentnego ust boli?",
      answer: `<ul>
          <li class="mt-2">
           Odczucia bólowe podczas zabiegu zależą od indywidualnej wytrzymałości na ból (tzw. próg bólu). Zazwyczaj klientki opisują to jako dyskomfort, lub mrowienie w okolicy zabiegowej.
          </li>
          <li class="mt-6">
            Dla pełnego komfortu podczas zabiegu stosowany jest płyn znieczulający, który niweluje nieprzyjemne odczucia.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Zalety makijażu permanentnego ust?",
      answer: `<ul>
          <li class="mt-6">
            <span class="font-medium">
              Trwały efekt makijażu. 
            </span>
            Permanentne usta utrzymują się przez długi okres, często od 1 do 3 lat. Usta stają się pełniejsze i bardziej podkreślone, co znacząco wpływa na pewność siebie. Makijaż permanentny nie zanika całkowicie, jednak po upływie ok. 2 lat zaleca się odświeżenie koloru.
          </li>
          <li class="mt-6">
            <span class="font-medium">Oszczędność czasu: </span> Dla osób, które mają mało czasu lub nie lubią wykonywać codziennego makijażu ust. Makijaż permanentny jest doskonałym rozwiązaniem, ponieważ eliminuje konieczność nakładania i zmywania produktów.
          </li>
            <li class="mt-6">
            <span class="font-medium">
              Atrakcyjniejszy i pełniejszy wygląd ust: 
            </span>
            Makijaż permanentny ust pozwala na korektę kształtu i koloru ust, co może znacząco poprawić wygląd i proporcje twarzy.
          </li>
        </ul>`,
    },
  ],
  eyeliner_makeup: [
    {
      id: 1,
      question: "Jak wygląda zabieg makijażu permanentnego kreski na powiece?",
      answer: `<ul>
          <li class="mt-2 font-medium">
            1. Konsultacja + wybór kształtu i grubość kreski
          </li>
          <li class="mt-2">
            Podczas konsultacji omawiane są oczekiwania oraz potrzeby klientki. Ustalamy kształt, grubość, a także rodzaj kreski.
          </li>
          <li class="mt-6 font-medium">
            2. Przygotowanie powiek i skóry
          </li>
          <li class="mt-2">
            Powieki są dokładnie oczyszczane z makijażu i innych produktów. Następnie przystępujemy do dezynfekcji skóry wokół oczu.
          </li>
             <li class="mt-6 font-medium">
            3. Aplikacja pigmentu
          </li>
          <li class="mt-2">
            Pigment jest starannie aplikowany na skórę powiek za pomocą iniekcji. Jest to moment, w którym można zastosować krem znieczulający, aby zminimalizować ryzyko dyskomfortu. 
          </li>
             <li class="mt-6 font-medium">
            4. Omówienie efektu
          </li>
          <li class="mt-2">
            Ostatni etap pozwala ocenić efekt. Klientka jest również informowana o zaleceniach pozabiegowych, a także ustalany jest termin wizyty kontrolnej.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Dla kogo jest wskazany makijaż permanentny kreski na powiece?",
      answer: `<ul>
          <li class="mt-2">
            <span class="font-medium">
              Dla kobiet o małych lub wąskich oczach:
            </span>
            Dla klientek, które chcą optycznie powiększyć oczy i nadać im większą intensywność.
          </li>
          <li class="mt-6">
            <span class="font-medium">Dla kobiet o nieregularnym kształcie oczu:</span> Dla klientek, których naturalny kształt oczu jest nieregularny lub asymetryczny. Makijaż permanentny kreski na powiece pozwala na dostosowanie go do własnych preferencji.
          </li>
            <li class="mt-6">
            <span class="font-medium">
              Dla kobiet pragnących trwałego efektu:
            </span>
            Dla tych, które nie chcą codziennie się malować. Makijaż  permanentny kreski na powiece może zapewnić trwały efekt przez wiele miesięcy.
          </li>
        </ul>`,
    },
    {
      id: 3,
      question: "Czy zabieg kreski permanentnej na powiece boli?",
      answer: `<ul>
          <li class="mt-2">
           Powieka jest miejscem bardzo delikatnym i wrażliwym dlatego wiele klientek obawia się bólu - zupełnie niepotrzebnie! Zabieg nie jest bolesny, choć może powodować dyskomfort lub “drażnić”. W celu zapewnienia komfortu, stosuje się krem znieczulający, aby klientka wyszła z gabinetu nie tylko piękna, ale również zrelaksowana!
          </li>
        </ul>`,
    },
  ],
  makeup_removal: [
    {
      id: 1,
      question: "Dla kogo wskazane jest usuwanie makijażu permanentnego?",
      answer: `<ul>
          <li class="mt-2">
           <span class="font-medium">Dla osób z nieudanym lub źle wykonanym makijażem permanentnym: </span>
           W przypadku niezadowalających efektów makijażu permanentnego usuwanie może być jedynym sposobem na przywrócenie naturalnego i estetycznego wyglądu.
          </li>
           <li class="mt-2">
           <span class="font-medium">Dla osób, które chcą zmienić styl/ wyraz twarzy: </span>
           Jeśli zmieniasz swoje preferencje kosmetyczne i Twój obecny makijaż permanentny nie spełnia Twoich wymagań, usunięcie jest rozsądną opcją.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Czy usuwanie makijażu permanentnego boli?",
      answer:
        "Zabieg usuwania makijażu nie jest bolesny, jednak może powodować dyskomfort. Wszystko zależy od indywidualnego progu bólu klientki.",
    },
  ],
  laser_remover: [
    {
      id: 1,
      question:
        "Ile zabiegów laserem należy wykonać, aby usunąć makijaż permanentny?",
      answer: `<ul>
          <li class="mt-2">
           Ilość zabiegów jest ustalana indywidualnie, ponieważ zależy od wielu czynników:
          </li>
          <li class="mt-2">
            - Stanu i reakcji skóry na laser
          </li>
          <li class="mt-2">
            - Gęstości barwnika użytego podczas makijażu permanentnego
          </li>
          <li class="mt-2">
            - Głębokości, na jakiej został umieszczony pigment
          </li>
          <li class="mt-2">
            - Koloru barwnika, który został użyty podczas makijażu permanentnego
          </li>
            <li class="mt-4">
            Jeśli więc borykasz się ze źle wykonanym makijażem permanentnym lub na przestrzeni lat zmienił się Twój gust, jeśli chodzi o kosmetykę – laser może być świetnym rozwiązaniem.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Zalety laserowego usuwania makijażu permanentnego?",
      answer: `<ul>
          <li class="mt-2 font-medium">
           1. Skuteczność
          </li>
          <li class="mt-2">
            Zabieg laserowy jest jednym z najskuteczniejszych sposobów na usunięcie makijażu permanentnego.
          </li>
          <li class="mt-6 font-medium">
            2. Minimalna inwazyjność
          </li>
          <li class="mt-2">
            Zabieg laserowy jest mniej inwazyjny, nie pozostawia blizn, przez co cieszy się dużą popularnością.
          </li>
        </ul>`,
    },
    {
      id: 3,
      question:
        "Czy zabieg usuwania makijażu permanentnego Removerem jest bezpieczny?",
      answer: `<ul>
          <li class="mt-2">
           Remover jest w pełni bezpieczną i często wybieraną metodą usuwania makijażu permanentnego. Nie zawiera substancji szkodliwych, kwasów, ani też substancji toksycznych.
          </li>
        </ul>`,
    },
  ],
  detox_peel: [
    {
      id: 1,
      question: "Jak wygląda zabieg Detox Peel?",
      answer: `<ul>
          <li class="mt-6">
            <span class="font-medium">
              Etap 1: 
            </span>
            Skóra jest dokładnie oczyszczana z zanieczyszczeń oraz pozostałości po makijażu.
          </li>
          <li class="mt-6">
            <span class="font-medium">Etap 2: </span> Aplikowany jest lawendowy Pre-peel, który w delikatny sposób usuwa martwe warstwy rogowatego naskórka, wygładza, a także zmiękcza skórę twarzy. Lawendowy Pre-peel działa również antyoksydacyjnie i przygotowuje skórę do dalszych etapów zabiegu.
          </li>
            <li class="mt-6">
            <span class="font-medium">
              Etap 3: 
            </span>
            Zastosowana zostaje gąbka morska, która pobudza metabolizm komórkowy, eliminuje zaskórniki, krosty i grudki, a także działa keratolitycznie. Ten etap zabiegu wykazuje również działanie rozjaśniające i wyrównujące koloryt skóry twarzy.
          </li>
          <li class="mt-6">
            <span class="font-medium">Etap 4: </span> Na skórę aplikowane jest kojące awokado, które neutralizuje drażniące działanie szorstkich alg i gąbek morskich. Awokado ściąga rozszerzone ujścia gruczołów łojowych, a także przyspiesza regenerację skóry twarzy.
          </li>
            <li class="mt-6">
            Preparaty użyte podczas zabiegu mają konsystencję kremu, dzięki czemu pacjentka nie odczuwa negatywnych skutków tarcia. Bazowym składnikiem jest gąbka morska spongilla, która rozpuszczając się, dostarcza skórze bogactwa cennych minerałów.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Kiedy warto skorzystać z Detox Peel?",
      answer: `<ul>
          <li class="mt-2">
           - Chęć poprawy ogólnego stanu i wyglądu skóry twarzy
          </li>
          <li class="mt-2">
            - Skóra palacza
          </li>
          <li class="mt-2">
            - Cera ziemista i pozbawiona blasku
          </li>
            <li class="mt-2">
            - Problem z łojotokiem oraz cerą przetłuszczającą się
          </li>
          <li class="mt-2">
            - Plamy starcze, plamy wątrobowe i przebarwienia
          </li>
          <li class="mt-2">
            - Trądzik (każde stadium)
          </li>
          <li class="mt-2">
            - Uporczywe zaskórniki
          </li>
        </ul>`,
    },
  ],
  chemical_peeling: [
    {
      id: 1,
      question: "Jakie efekty można osiągnąć dzięki peelingom chemicznym?",
      answer: `<ul>
          <li class="mt-2">
           Peelingi chemiczne oferują wiele korzyści dla skóry, w tym:
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Wygładzenie skóry:
            </span>
            Peelingi usuwają martwe komórki naskórka, co prowadzi do wygładzenia skóry i poprawy jej tekstury.
          </li>
          <li class="mt-6">
            <span class="font-medium">Redukcja lub spłycenie zmarszczek:</span> Niektóre peelingi, zwłaszcza te zawierające kwasy, mogą zmniejszyć widoczność zmarszczek i drobnych linii.
          </li>
            <li class="mt-6">
            <span class="font-medium">
              Usuwanie przebarwień:
            </span>
            Peelingi chemiczne są skuteczną metodą w redukcji przebarwień spowodowanych słońcem, fotostarzeniem się skóry lub trądzikiem.
          </li>
          <li class="mt-6">
            <span class="font-medium">Redukcja trądziku:</span> Peelingi mogą pomóc w leczeniu trądziku, redukując ilość zaskórników i zapobiegając powstawaniu nowych wyprysków.
          </li>
            <li class="mt-6">
            <span class="font-medium">
              Poprawa kolorytu skóry:
            </span>
            Po zabiegu skóra staje się bardziej promienna i rozświetlona.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Dla kogo są przeznaczone peelingi chemiczne?",
      answer: `<ul>
          <li class="mt-2">
           Mogą być stosowane przez wiele osób, ale nie każdy typ skóry jest do nich odpowiedni. Najlepiej sprawdzają się u osób, które borykają się z:
          </li>
          <li class="mt-6">
            - Skórą przetłuszczającą się
          </li>
          <li class="mt-6">
            - Skórą trądzikową
          </li>
            <li class="mt-6">
            - Skórą z przebarwieniami
          </li>
          <li class="mt-6">
            - Skórą ze zmarszczkami i bruzdami
          </li>
          <li class="mt-6">
            - Skórą z bliznami potrądzikowymi
          </li>
        </ul>`,
    },
  ],
  mesotherapy: [
    {
      id: 1,
      question: "Czy zabieg mezoterapii igłowej jest bolesny?",
      answer: `<ul>
          <li class="mt-2">
            Wszystko zależy od indywidualnego progu bólu - nakłucia wykonywane są cienką igłą, jednak zdarza się, że pacjentki zgłaszają drobny dyskomfort. Aby zminimalizować nieprzyjemne odczucia, może zostać zastosowane znieczulenie.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Dla kogo dedykowany jest zabieg mezoterapii igłowej?",
      answer: `<ul>
          <li class="mt-2">
            <span class="font-medium">
              Dla osób z widocznymi oznakami starzenia się skóry:
            </span>
            Mezoterapia igłowa może pomóc w redukcji zmarszczek, linii mimicznych i ogólnego zwiotczenia skóry. 
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Dla osób z problemami skórnymi:
            </span>
            Zabieg może być skutecznym rozwiązaniem dla osób borykających się z trądzikiem, bliznami, przebarwieniami, rozszerzonymi porami, czy nawet cellulitem. Specjalista dostosowuje skład koktajlu mezoterapeutycznego do konkretnego problemu skórnego.
          </li>
          <li class="mt-6">
            <span class="font-medium">Dla osób borykających się z utratą elastyczności i jędrności skóry:</span> Mezoterapia igłowa może pomóc poprawić elastyczność i jędrność skóry, co jest szczególnie ważne dla osób, które zauważają obniżenie owalu twarzy lub opadające powieki.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Dla osób chcących odświeżyć skórę i dodać jej blasku:
            </span>
            Mezoterapia igłowa może dać efekt nawilżonej, odżywionej i promiennej skóry, co jest atrakcyjne dla osób, które po prostu chcą poprawić ogólny wygląd skóry twarzy. 
          </li>
        </ul>`,
    },
  ],
  tissue_stimulator: [
    {
      id: 1,
      question: "Jakie efekty dają zabiegi z użyciem stymulatorów tkankowych?",
      answer: `<ul>
          <li class="mt-2">
            <span class="font-medium">
              Redukcja zmarszczek
            </span>
            - Stymulatory tkankowe są skuteczną opcją dla osób poszukujących naturalnego wyglądu, bez efektu "maski" typowego dla niektórych wypełniaczy. Działają one stopniowo, nadając skórze naturalny wygląd i pozwalając uniknąć nagłych zmian.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Podniesienie owalu twarzy
            </span>
            - Stymulatory tkankowe mogą być stosowane do modelowania konturów twarzy, zapewniając efekt uniesienia i ujędrnienia skóry.
          </li>
          <li class="mt-6">
            <span class="font-medium">Poprawa elastyczności i jędrności skóry </span> - Oprócz twarzy, stymulatory tkankowe znajdują zastosowanie w poprawie jakości skóry na ciele, takiej jak dekolt, brzuch, uda, dłonie czy ramiona.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Redukcja blizn
            </span>
            - Stymulatory tkankowe przyśpieszają oraz wzmacniają proces odbudowy skóry, co sprzyja blednięciu oraz łagodzeniu blizn. 
          </li>
        </ul>`,
    },
  ],
  lip_modeling: [
    {
      id: 1,
      question: "Etapy gojenia się ust po zabiegu?",
      answer: `<ul>
          <li class="mt-2 font-medium">
            Etap 1 (do 24h po zabiegu)
          </li>
          <li class="mt-2">
           Ze względu na fakt, iż zabieg wymaga ingerencji iniekcyjnej, preparat musi mieć czas, aby zintegrować się z tkankami. Cały proces wiązania może sprawić, że przez 24 godziny możesz odczuwać, taki dyskomfort jak:
          </li>
          <li class="mt-2">
            <ul>
              <li class="mt-2 ml-6">
              <span class="font-medium">Ból ust</span> - szczególnie przy dotyku. Ból może się nasilać, jest to zupełnie naturalny objaw i nie masz się czego obawiać,
              </li>
              <li class="mt-2 ml-6">
              <span class="font-medium">Obrzęk</span> - opuchlizna jest naturalnym efektem powiększania i będzie stopniowo zanikać,
              </li>
              <li class="mt-2 ml-6">
              <span class="font-medium">Uczucie “obcych” ust</span> - jest to dyskomfort, który zgłasza większość Pań, mając wrażenie, że ktoś “przyczepił” im usta. Jest to również naturalna reakcja, którą z czasem przestaniesz odczuwać, 
              </li>
                <li class="mt-2 ml-6">
              <span class="font-medium">Suchość ust</span> - ze względu na wiązanie wody, które powoduje kwas hialuronowy, możesz mieć wrażenie suchych ust,
              </li>
              <li class="mt-2 ml-6">
              <span class="font-medium">Ból głowy</span> - ból głowy często związany jest z dyskomfortem psychicznym, szczególnie, jeśli była to Twoja pierwsza wizyta,
              </li>
              <li class="mt-2 ml-6">
              <span class="font-medium">Twardość ust</span> - usta mogą wydawać Ci się twarde 
              </li>
              <li class="mt-2 ml-6">
              <span class="font-medium">Grudki na powierzchni ust</span> - jest to naturalne zjawisko po modelowaniu ust, ponieważ kwas hialuronowy podajemy za pomocą mikro igieł. Nakłucia pozostają na zewnętrznej warstwie, stąd widoczne grudki. Jest to naturalny proces i również minie w ciągu kilku dni od zabiegu
              </li>
            </ul>
          </li>
          <li class="mt-2">
           Przez kilka pierwszych dni wiele kobiet zwraca uwagę na najmniejszy detal, a każdy dyskomfort powoduje strach i obawę, czy wszystko jest w porządku.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "",
      answer: `<ul>
          <li class="mt-8 font-medium">
            Etap 2 (od 24h do 3 dni) 
          </li>
          <li class="mt-2">
           Etap 2 jest kwestią mocno indywidualną. Są panie, u których opuchlizna staje się widocznie mniejsza, a są Panie, u których wręcz się nasila. Objawy, które wciąż możesz odczuwać to z pewnością:
          </li>
          <li class="mt-2">
            <ul>
              <li class="mt-2 ml-6">
              <span class="font-medium">Nierówności</span> - wrażenie, że usta są nierówne, spowodowane jest wciąż efektem opuchlizny pozabiegowej. Co ważne: opuchlizna może nie być widoczna gołym okiem, ale nie oznacza to, że nie ma jej w miejscu zabiegowym. Pamiętaj, że wciąż Twoje usta są w procesie gojenia,
              </li>
              <li class="mt-2 ml-6">
              <span class="font-medium">Grudki</span> - mikro-krwiaczki mogą wciąż być obecne na Twoich ustach, daj im jeszcze trochę czasu,
              </li>
              <li class="mt-2 ml-6">
              <span class="font-medium">Twardość</span> - usta wciąż mogą pozostać twarde i nie powinno to budzić Twojego niepokoju.
            </ul>
          </li>
          <li class="mt-2">
           Wszystkie powyższe objawy są naturalnym procesem gojenia - nie masz się czym martwić. Po 2 tygodniach widzimy się na kontroli! Najwięcej Pań zgłasza, że pierwsze 3 dni po zabiegu są najgorsze, każdy kolejny dzień zmniejsza poziom dyskomfortu.
          </li>
           <li class="mt-8 font-medium">
            Etap 3 - ułożenie kwasu i uzyskanie pożądanego efektu
          </li>
          <li class="mt-2">
           Tutaj już można podziwiać się w lustrze! Do pełnego wygojenia ust należy poczekać miesiąc.
          </li>
        </ul>`,
    },
    {
      id: 3,
      question: "Jak długo utrzymuje się efekt ust modelowanych na płasko?",
      answer: `<ul>
          <li class="mt-2">
            Rezultaty pozabiegowe utrzymują się od 6 do 12 miesięcy. Należy jednak pamiętać, że kwas hialuronowy jest naturalną substancją, która występuje w naszym organizmie, dlatego długość efektów jest kwestią indywidualną zależną od trybu życia, uwarunkowań genetycznych, a także diety.
          </li>
          <li class="mt-6">
            Im więcej używek jest w naszym codziennym życiu, tym szybciej kwas hialuronowy zostanie wydalony przez organizm. U niektórych osób, efekty zabiegu utrzymują się nawet do 18 miesięcy!
          </li>
        </ul>`,
    },
  ],
  lipolysis: [
    {
      id: 1,
      question: "Dla kogo jest przeznaczony zabieg lipolizy iniekcyjnej?",
      answer: `<ul>
          <li class="mt-2">
            <span class="font-medium">
              Dla osób z prawidłowym BMI.
            </span>
            Zabieg nie jest metodą odchudzania i nie jest odpowiedni dla osób z nadwagą lub otyłością.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Dla osób, które borykają się z nadmiarem tkanki tłuszczowej w określonym obszarze.
            </span>
            Lipoliza iniekcyjna jest skierowana do tych, którzy chcą pozbyć się uporczywych „poduszeczek” tłuszczu w konkretnych miejscach.
          </li>
          <li class="mt-6">
            <span class="font-medium">Dla osób zdrowych.</span> Osoby z chorobami układu sercowo-naczyniowego, cukrzycą, lub innymi poważnymi problemami zdrowotnymi nie będą zakwalifikowani do zabiegu.
          </li>
        </ul>`,
    },
  ],
  botulinum_toxin: [
    {
      id: 1,
      question: "Efekty zabiegu z użyciem toksyny botulinowej?",
      answer: `<ul>
          <li class="mt-2">
            <span class="font-medium">
              Wygładzenie zmarszczek:
            </span>
            Toksyna botulinowa jest najczęściej stosowana do wygładzenia zmarszczek mimicznych np. zmarszczki na czole, "kurze łapki" wokół oczu, lwia zmarszczka.
          </li>
          <li class="mt-6">
            <span class="font-medium">
              Podniesienie brwi:
            </span>
            Zabieg może także być wykorzystywany do podniesienia opadających brwi, co nadaje twarzy bardziej młodzieńczy wygląd.
          </li>
          <li class="mt-6">
            <span class="font-medium">Poprawa pewności siebie: </span> Dla wielu osób Botoks może korzystnie wpłynąć na pewność siebie i poczucie atrakcyjności, poprawiając wygląd i usuwając widoczne oznaki starzenia się skóry.
          </li>
           <li class="mt-6">
            Podczas zabiegu ilość preparatu dobierana jest indywidualnie, dzięki czemu uzyskujemy efekt naturalnie jędrnej i odmłodzonej skóry.
          </li>
        </ul>`,
    },
    {
      id: 2,
      question: "Do kogo skierowany jest zabieg?",
      answer: `<ul>
          <li class="mt-2">
            Zabieg z użyciem botoksu skierowany jest do wszystkich, którzy chcą poprawić wygląd swojej skóry lub zadbać odpowiednio wcześnie o profilaktykę anti-aging. Z utratą jędrności skóry borykamy się już od ok. 25 roku życia! Dzięki regularnym zabiegom z użyciem botoksu zmarszczki, które dopiero się pojawiają - nie będą się utrwalać ani też pogłębiać.
          </li>
          <li class="mt-6">
            Toksyna botulinowa polecana jest również dla osób, które cechuje silna i dynamiczna mimika twarzy, ponieważ w dużej mierze to właśnie mimika sprawia, że na naszej twarzy powstają liczne bruzdy oraz linie (np. lwia zmarszczka, która wielu osobom kojarzy się ze złością).  
          </li>
        </ul>`,
    },
    {
      id: 3,
      question: "Czy botoks boli?",
      answer:
        "Toksyna botulinowa podawana jest bardzo cienką igłą, dzięki czemu minimalizujemy ryzyko odczuć bólowych. Oczywiście zdarzają się osoby, które odczuwają dyskomfort, jednak często porównywany jest on do delikatnego mrowienia.",
    },
  ],
};

const contraindicationsMap = {
  lashes_styling: [
    {
      id: 1,
      text: `
          <span class="font-medium">Infekcje oraz choroby oka: </span>
          Osoby, które cierpią na zakażenia oczu, takie jak zapalenie spojówek
          czy inne infekcje oczu, nie mogą poddawać się zabiegowi stylizacji
          rzęs. Zabieg może prowadzić do rozprzestrzeniania się infekcji lub jej nasilenia.
      `,
    },
    {
      id: 2,
      text: `
          <span class="font-medium">Alergie: </span>
          Osoby, które mają alergie na składniki używane w kleju do rzęs lub
          innych kosmetykach używanych podczas zabiegu, mogą doświadczać reakcji
          alergicznych, takich jak swędzenie, zaczerwienienie czy obrzęk.
      `,
    },
    {
      id: 3,
      text: `
          <span class="font-medium">Choroby oczu/ skóry: </span>
          Osoby z ciężkimi chorobami oczu, takimi jak jaskra, czy skóry w
          okolicach oczu, takimi jak liszajec lub trądzik różowaty, powinny
          unikać tego typu zabiegu ze względu na ryzyko powikłań.
      `,
    },
    {
      id: 4,
      text: `<span class="font-medium">Naświetlania/ radioterapia/ chemioterapia</span>`,
    },
    {
      id: 5,
      text: `
          <span class="font-medium">Zbyt mała ilość własnych rzęs: </span>
          Jeśli rzęsy są uszkodzone, powyrywane lub w bardzo słabej kondycji,
          należy poczekać do momentu, aż ich stan się poprawi.
      `,
    },
  ],
  non_standard_lashes_styling: [],
  lashes_lamination: [
    {
      id: 1,
      text: "Zakażenia oczu, takie jak zapalenie spojówek czy infekcje",
    },
    {
      id: 2,
      text: "Nadwrażliwość lub alergie na składniki używane w produkcie do laminacji rzęs",
    },
    {
      id: 3,
      text: "Uszkodzenia rzęs",
    },
    {
      id: 4,
      text: "Choroby oczu lub skóry wokół oczu",
    },
    {
      id: 5,
      text: "Ciąża 	i okres karmienia piersią",
    },
  ],
  eyebrow_lamination: [
    {
      id: 1,
      text: "Skóra wokół oczu jest podrażniona, uszkodzona lub ma otwarte rany",
    },
    {
      id: 2,
      text: "Alergia na składniki używane w produktach do laminacji brwi",
    },
    {
      id: 3,
      text: "Choroby skóry lub infekcje oczu",
    },
    {
      id: 4,
      text: "Trądzik",
    },
    {
      id: 5,
      text: "Ostry peeling wykonany do 48h przed zabiegiem laminacji brwi",
    },
    {
      id: 6,
      text: "Przesuszona skóra",
    },
    {
      id: 7,
      text: "Problemy skórne",
    },
    {
      id: 8,
      text: "Ciąża i okres karmienia piersią",
    },
    {
      id: 9,
      text: "Zabiegi złuszczające",
    },
  ],
  classic_henna: [
    {
      id: 1,
      text: "Skóra wokół brwi jest podrażniona, uszkodzona lub ma otwarte rany",
    },
    {
      id: 2,
      text: "Aktywne choroby zakaźne",
    },
    {
      id: 3,
      text: "Alergia na składniki używane w preparacie",
    },
    {
      id: 4,
      text: "Choroby skóry lub infekcje oczu",
    },
    {
      id: 5,
      text: "Ciąża i okres karmienia piersią",
    },
    {
      id: 6,
      text: "Zabiegi z użyciem kwasów/ peelingów/ mikrodermabrazji wykonane do 48h przed zabiegiem henny klasycznej",
    },
    {
      id: 7,
      text: "Stany ropne",
    },
    {
      id: 8,
      text: "Stany zapalne oczu",
    },
  ],
  powder_henna: [
    {
      id: 1,
      text: "Skóra wokół brwi jest podrażniona, uszkodzona lub ma otwarte rany",
    },
    {
      id: 2,
      text: "Alergia na składniki używane w produktach do henny",
    },
    {
      id: 3,
      text: "Choroby skóry lub infekcje oczu",
    },
    {
      id: 4,
      text: "Ciąża i okres karmienia piersią w określonych przypadkach",
    },
    {
      id: 5,
      text: "Aktywne choroby wirusowe lub bakteryjne",
    },
    {
      id: 6,
      text: "Aktywna łuszczyca",
    },
  ],
  eyebrow_makeup: [
    {
      id: 1,
      text: "Skóra wokół brwi jest podrażniona, uszkodzona lub ma otwarte rany",
    },
    {
      id: 2,
      text: "Alergia na składniki używane w pigmentach",
    },
    {
      id: 3,
      text: "Choroby skóry lub infekcje",
    },
    {
      id: 4,
      text: "Ciąża i okres karmienia piersią",
    },
    {
      id: 5,
      text: "Radioterapia",
    },
    {
      id: 6,
      text: "Chemioterapia",
    },
    {
      id: 7,
      text: "Nieleczona cukrzyca",
    },
    {
      id: 8,
      text: "Zaawansowane i nieleczone problemy hormonalne",
    },
    {
      id: 9,
      text: "Łuszczyca",
    },
    {
      id: 10,
      text: "Świeża opalenizna",
    },
    {
      id: 11,
      text: "Choroby oczu",
    },
  ],
  lip_makeup: [
    {
      id: 1,
      text: "Skóra wokół ust jest podrażniona, uszkodzona lub ma otwarte rany",
    },
    {
      id: 2,
      text: "Alergia na składniki używane w pigmentach",
    },
    {
      id: 3,
      text: "Choroby nowotworowe",
    },
    {
      id: 4,
      text: "Choroby skóry lub infekcje w okolicach ust (np. opryszczka)",
    },
    {
      id: 5,
      text: "Ciąża i okres 	karmienia piersią",
    },
    {
      id: 6,
      text: "Nieleczona cukrzyca oraz zaburzenia hormonalne",
    },
    {
      id: 7,
      text: "Epilepsja",
    },
  ],
  eyeliner_makeup: [
    {
      id: 1,
      text: "Infekcje lub choroby wirusowe (w przebiegu z gorączką i odruchem kaszlu)",
    },
    {
      id: 2,
      text: "Radioterapia",
    },
    {
      id: 3,
      text: "Chemioterapia",
    },
    {
      id: 4,
      text: "Nieleczona cukrzyca",
    },
    {
      id: 5,
      text: "Alergię na składniki używane w pigmentach",
    },
    {
      id: 6,
      text: "Choroby skóry lub infekcje w okolicach oczu",
    },
    {
      id: 7,
      text: "Łuszczyca",
    },
    {
      id: 8,
      text: "Nadpobudliwość",
    },
    {
      id: 9,
      text: "Bielactwo",
    },
    {
      id: 10,
      text: "Choroby autoimmunologiczne",
    },
    {
      id: 11,
      text: "Leki rozrzedzające krew",
    },
    {
      id: 12,
      text: "Antybiotykoterapia",
    },
    {
      id: 13,
      text: "Skóra z tendencją do powstawania bliznowców",
    },
  ],
  makeup_removal: [
    {
      id: 1,
      text: "Ciąża i karmienie piersią, w tym okresie nie zaleca się przeprowadzania tego typu zabiegów",
    },
    {
      id: 2,
      text: "Nieleczona cukrzyca, osoby z cukrzycą mogą mieć trudniejszy proces gojenia i reakcje skórne",
    },
    {
      id: 3,
      text: "Cera nadwrażliwa: Osoby z wrażliwą skórą mogą doświadczać reakcji alergicznych lub podrażnień",
    },
    {
      id: 4,
      text: "Aktywne infekcje oraz choroby",
    },
    {
      id: 5,
      text: "Zmiany skórne",
    },
    {
      id: 6,
      text: "Świeża opalenizna",
    },
    {
      id: 7,
      text: "Choroby skóry",
    },
    {
      id: 8,
      text: "Chemioterapia i radioterapia",
    },
    {
      id: 9,
      text: "Epilepsja",
    },
  ],
  laser_remover: [
    {
      id: 1,
      text: "Ciąża i karmienie piersią",
    },
    {
      id: 2,
      text: "Radioterapia i chemioterapia",
    },
    {
      id: 3,
      text: "Nieleczona cukrzyca",
    },
    {
      id: 4,
      text: "Nieleczone zaburzenia hormonalne",
    },
    {
      id: 5,
      text: "Aktywne infekcje oraz choroby",
    },
    {
      id: 6,
      text: "Cera wrażliwa",
    },
    {
      id: 7,
      text: "Infekcje skórne",
    },
    {
      id: 8,
      text: "Łuszczyca",
    },
    {
      id: 9,
      text: "AZS",
    },
  ],
  detox_peel: [
    {
      id: 1,
      text: "Ciąża i karmienie piersią",
    },
    {
      id: 2,
      text: "Otwarte rany, oparzenia lub infekcje skóry na obszarze twarzy",
    },
    {
      id: 3,
      text: "Skóra wrażliwa lub skłonna do alergii",
    },
    {
      id: 4,
      text: "Cukrzyca",
    },
    {
      id: 5,
      text: "Aktywne infekcje",
    },
    {
      id: 6,
      text: "Aktywne choroby wirusowe i bakteryjne",
    },
    {
      id: 7,
      text: "Infekcje grzybicze",
    },
    {
      id: 8,
      text: "Opryszczka",
    },
  ],
  chemical_peeling: [
    {
      id: 1,
      text: "Ciąża i karmienie piersią",
    },
    {
      id: 2,
      text: "Opryszczka",
    },
    {
      id: 3,
      text: "Skóra uszkodzona lub z infekcjami",
    },
    {
      id: 4,
      text: "Aktywna opalenizna",
    },
    {
      id: 5,
      text: "Historia bliznowców keloidowych",
    },
    {
      id: 6,
      text: "Skłonność do trądziku różowatego",
    },
    {
      id: 7,
      text: "Zastosowanie izotretynoiny w ostatnich 6 miesiącach",
    },
  ],
  mesotherapy: [
    {
      id: 1,
      text: "Ciąża i laktacja",
    },
    {
      id: 2,
      text: "Choroby nowotworowe",
    },
    {
      id: 3,
      text: "Opryszczka",
    },
    {
      id: 4,
      text: "Aktywne infekcje skórne",
    },
    {
      id: 5,
      text: "Radioterapia i chemioterapia",
    },
    {
      id: 6,
      text: "Epilepsja",
    },
    {
      id: 7,
      text: "Nieleczona cukrzyca",
    },
    {
      id: 8,
      text: "Stosowanie leków przeciwzakrzepowych",
    },
    {
      id: 9,
      text: "Skóra naczyniowa",
    },
    {
      id: 10,
      text: "Alergia na składniki zawarte w preparacie",
    },
  ],
  tissue_stimulator: [
    {
      id: 1,
      text: "Ciąża i laktacja - Zabieg nie jest zalecany w czasie ciąży i podczas karmienia piersią z powodu braku wystarczających badań dotyczących bezpieczeństwa.",
    },
    {
      id: 2,
      text: "Choroby autoimmunologiczne - Osoby cierpiące na choroby autoimmunologiczne, takie jak toczeń, Hashimoto, czy reumatoidalne zapalenie stawów, nie będą zakwalifikowani do zabiegu.",
    },
    {
      id: 3,
      text: "Alergie i nadwrażliwość - Osoby z nadwrażliwością na składniki zawarte w preparatach lub historią alergii powinny skonsultować się z lekarzem przed zabiegiem.",
    },
    {
      id: 4,
      text: "Opryszczka",
    },
    {
      id: 5,
      text: "Stany zapalne skóry",
    },
    {
      id: 6,
      text: "Infekcje bakteryjne i wirusowe (aktywne)",
    },
    {
      id: 7,
      text: "Epilepsja",
    },
    {
      id: 8,
      text: "Zaburzenia krzepnięcia krwi",
    },
  ],
  lip_modeling: [
    {
      id: 1,
      text: "Ciąża i laktacja",
    },
    {
      id: 2,
      text: "Alergie i nadwrażliwość na składniki stosowane podczas zabiegu",
    },
    {
      id: 3,
      text: "Stany zapalne skóry",
    },
    {
      id: 4,
      text: "Opryszczka",
    },
    {
      id: 5,
      text: "Zakażenia w okolicy ust",
    },
    {
      id: 6,
      text: "Aktywne infekcje bakteryjne i wirusowe",
    },
    {
      id: 7,
      text: "Cukrzyca",
    },
    {
      id: 8,
      text: "Zażywanie leków rozrzedzających krew",
    },
    {
      id: 9,
      text: "Choroby nowotworowe",
    },
    {
      id: 10,
      text: "Bielactwo",
    },
    {
      id: 11,
      text: "Łuszczyca",
    },
    {
      id: 12,
      text: "Trądzik różowaty",
    },
    {
      id: 13,
      text: "Próchnica zębów oraz inne infekcje jamy ustnej",
    },
    {
      id: 14,
      text: "Stosowanie leków przeciwzapalnych",
    },
    {
      id: 15,
      text: "Zmiany ropne",
    },
    {
      id: 16,
      text: "Zaburzenia krzepliwości krwi",
    },
    {
      id: 17,
      text: "Epilepsja",
    },
  ],
  lipolysis: [
    {
      id: 1,
      text: "Ciąża i laktacja",
    },
    {
      id: 2,
      text: "Epilepsja",
    },
    {
      id: 3,
      text: "Nadwaga i otyłość",
    },
    {
      id: 4,
      text: "Uczulenie na którykolwiek składnik preparatu",
    },
    {
      id: 5,
      text: "Zaburzenia krzepnięcia krwi",
    },
    {
      id: 6,
      text: "Aktywne choroby wirusowe lub bakteryjne",
    },
    {
      id: 7,
      text: "Choroby autoimmunologiczne",
    },
    {
      id: 8,
      text: "Nieleczona cukrzyca",
    },
    {
      id: 9,
      text: "Choroby wątroby",
    },
    {
      id: 10,
      text: "Choroby nerek",
    },
  ],
  botulinum_toxin: [
    {
      id: 1,
      text: "Ciąża i laktacja",
    },
    {
      id: 2,
      text: "Choroby mięśni",
    },
    {
      id: 3,
      text: "Zaburzenia krzepnięcia",
    },
    {
      id: 4,
      text: "Antybiotykoterapia",
    },
    {
      id: 5,
      text: "Alergie i nadwrażliwość na składniki toksyny botulinowej",
    },
    {
      id: 6,
      text: "Aktywne infekcje oraz choroby",
    },
    {
      id: 7,
      text: "Epilepsja",
    },
    {
      id: 8,
      text: "Chemioterapia",
    },
    {
      id: 9,
      text: "Radioterapia",
    },
  ],
};

export default {
  name: "QAndAMakeup",
  computed: {
    activeSection() {
      return this.$store.state.activeSection;
    },
    currentMethods() {
      return faqsMap[this.activeSection] || [];
    },
    currentContraindications() {
      return contraindicationsMap[this.activeSection] || [];
    },
  },
  methods: {
    shouldRenderForLaserRemover() {
      const contraindicationSections = ["laser_remover"];
      return contraindicationSections.includes(this.activeSection);
    },

    shouldRenderForNonStandardLashesStyling() {
      const contraindicationSections = ["non_standard_lashes_styling"];
      return contraindicationSections.includes(this.activeSection);
    },

    getContraindicationIntro(section) {
      const contraindicationIntroMap = {
        lashes_styling:
          "Choć zabieg stylizacji rzęs jest w pełni bezpieczny i bezbolesny to nie jest odpowiedni dla wszystkich. Istnieją pewne przeciwwskazania do tego rodzaju zabiegu, które należy uwzględnić przed jego wykonaniem:",
        lashes_lamination:
          "Choć laminacja rzęs jest stosunkowo bezpiecznym zabiegiem, istnieją pewne przeciwwskazania, które mogą wykluczyć możliwość jego przeprowadzenia:",
        eyebrow_lamination:
          "Podobnie jak w przypadku innych zabiegów kosmetycznych, istnieją pewne przeciwwskazania do zabiegu laminacji brwi. Oto niektóre z nich:",
        classic_henna:
          "Podobnie jak w przypadku innych zabiegów kosmetycznych, istnieją pewne przeciwwskazania do zabiegu henny klasycznej dla brwi. Oto niektóre z nich:",
        powder_henna: "",
        eyebrow_makeup:
          "Chociaż zabieg makijażu permanentnego brwi jest stosunkowo bezpieczny, istnieją pewne przeciwwskazania do jego wykonania. Oto niektóre z nich:",
        lip_makeup:
          "Chociaż zabieg makijażu permanentnego ust jest bezpieczny, istnieją pewne przeciwwskazania do jego wykonania. Oto niektóre z nich:",
        eyeliner_makeup:
          "Chociaż zabieg makijażu permanentnego kreski na powiece jest w pełni bezpieczny, istnieją pewne przeciwwskazania do jego wykonania. Oto niektóre z nich:",
        laser_remover:
          "Mimo że zabieg laserowego usuwania makijażu jest stosunkowo bezpieczny, istnieją pewne przeciwwskazania i zalecenia po zabiegu, które warto wziąć pod uwagę:",
        detox_peel:
          "Mimo wielu korzyści zabieg Detox Peel nie jest odpowiedni dla wszystkich. Istnieją przeciwwskazania, które należy uwzględnić, takie jak:",
        chemical_peeling:
          "Mimo że peelingi chemiczne mogą przynieść wiele korzyści, istnieją pewne przeciwwskazania do zabiegu z ich użyciem:",
        mesotherapy:
          "Mimo że mezoterapia igłowa jest stosunkowo bezpiecznym zabiegiem, istnieją pewne przeciwwskazania, które warto wziąć pod uwagę:",
        tissue_stimulator: "",
        lip_modeling: "",
        lipolysis:
          "Mimo że lipoliza iniekcyjna jest stosunkowo bezpieczna, istnieją pewne przeciwwskazania, które warto wziąć pod uwagę:",
        botulinum_toxin:
          "Zabieg toksyną botulinową jest całkowicie bezpieczny, jednak jak w przypadku każdego zabiegu, istnieją pewne przeciwwskazania, które warto wziąć pod uwagę:",
      };
      return contraindicationIntroMap[section] || "";
    },
  },
  setup() {
    return {
      faqsMap,
      contraindicationsMap,
    };
  },
};
</script>

<style scoped></style>
