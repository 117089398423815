import { createStore } from "vuex";

export default createStore({
  state: {
    displayLashesStyling: false,
    displayNonStandardLashesStyling: false,
    displayLashesLamination: false,
    displayEyebrowLamination: false,
    displayClassicHenna: false,
    displayPowderHenna: false,
    displayEyebrowMakeup: false,
    displayLipMakeup: false,
    displayEyelinerMakeup: false,
    displayMakeupRemoval: false,
    displayLaserRemover: false,
    displayDetoxPeel: false,
    displayChemicalPeeling: false,
    displayMesotherapy: false,
    displayTissueStimulator: false,
    displayLipModeling: false,
    displayLipolysis: false,
    displayBotulinumToxin: false,
    activeSection: null,
  },
  mutations: {
    TOGGLE_DISPLAY_LASHES_STYLING(state) {
      state.displayLashesStyling = !state.displayLashesStyling;
    },
    TOGGLE_DISPLAY_NON_STANDARD_LASHES_STYLING(state) {
      state.displayNonStandardLashesStyling =
        !state.displayNonStandardLashesStyling;
    },
    TOGGLE_DISPLAY_LASHES_LAMINATION(state) {
      state.displayLashesLamination = !state.displayLashesLamination;
    },
    TOGGLE_DISPLAY_EYEBROW_LAMINATION(state) {
      state.displayEyebrowLamination = !state.displayEyebrowLamination;
    },
    TOGGLE_DISPLAY_CLASSIC_HENNA(state) {
      state.displayClassicHenna = !state.displayClassicHenna;
    },
    TOGGLE_DISPLAY_POWDER_HENNA(state) {
      state.displayPowderHenna = !state.displayPowderHenna;
    },
    TOGGLE_DISPLAY_EYEBROW_MAKEUP(state) {
      state.displayEyebrowMakeup = !state.displayEyebrowMakeup;
    },
    TOGGLE_DISPLAY_LIP_MAKEUP(state) {
      state.displayLipMakeup = !state.displayLipMakeup;
    },
    TOGGLE_DISPLAY_EYELINER_MAKEUP(state) {
      state.displayEyelinerMakeup = !state.displayEyelinerMakeup;
    },
    TOGGLE_DISPLAY_MAKEUP_REMOVAL(state) {
      state.displayMakeupRemoval = !state.displayMakeupRemoval;
    },
    TOGGLE_DISPLAY_LASER_REMOVER(state) {
      state.displayLaserRemover = !state.displayLaserRemover;
    },
    TOGGLE_DISPLAY_DETOX_PEEL(state) {
      state.displayDetoxPeel = !state.displayDetoxPeel;
    },
    TOGGLE_DISPLAY_CHEMICAL_PEELING(state) {
      state.displayChemicalPeeling = !state.displayChemicalPeeling;
    },
    TOGGLE_DISPLAY_MESOTHERAPY(state) {
      state.displayMesotherapy = !state.displayMesotherapy;
    },
    TOGGLE_DISPLAY_TISSUE_STIMULATOR(state) {
      state.displayTissueStimulator = !state.displayTissueStimulator;
    },
    TOGGLE_DISPLAY_LIP_MODELING(state) {
      state.displayLipModeling = !state.displayLipModeling;
    },
    TOGGLE_DISPLAY_LIPOLYSIS(state) {
      state.displayLipolysis = !state.displayLipolysis;
    },
    TOGGLE_DISPLAY_BOTULINUM_TOXIN(state) {
      state.displayBotulinumToxin = !state.displayBotulinumToxin;
    },
    SET_ACTIVE_SECTION(state, section) {
      state.activeSection = section;
    },
  },
  actions: {},
  modules: {},
});
