<template>
  <section
    v-if="getHeadingText(activeSection)"
    :id="activeSection"
    class="relative h-auto"
  >
    <div
      class="mx-auto my-8 max-w-7xl border-papi-gold-200 px-4 pb-16 pt-0 sm:py-20 md:my-16 md:border md:py-16 lg:px-8"
    >
      <div class="mx-auto text-center">
        <h2
          class="font-dm-serif-display text-xl font-medium leading-7 tracking-wide text-papi-black-100 sm:text-3xl"
        >
          Zalecenia pozabiegowe{{ getHeadingText(activeSection) }}
        </h2>
      </div>

      <div class="mt-14 md:mt-20">
        <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:gap-6">
          <li
            v-for="step in currentSteps"
            :key="step.id"
            class="col-span-1 flex h-auto flex-wrap rounded-md shadow-sm sm:h-28 sm:flex-nowrap lg:h-24"
          >
            <div
              class="flex h-10 w-full flex-shrink-0 items-center justify-center rounded-l-sm bg-papi-gold-200 text-base font-medium tracking-widest text-white sm:h-auto sm:w-28"
            >
              {{ step.no }}
            </div>
            <div
              class="flex flex-1 items-center justify-between rounded-r-sm bg-papi-gray-100"
            >
              <div
                class="flex-1 p-6 text-md font-light text-papi-gray-300 sm:p-4 md:text-base"
              >
                <p v-html="step.text" class="mt-2" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
const stepsMap = {
  lashes_styling: [
    {
      id: 1,
      no: "KROK 1",
      text: `<span class="font-medium">Unikaj wilgoci: </span>Po zabiegu przez 24-48 godzin unikaj kontaktu rzęs z wodą i parą, ponieważ może to wpłynąć na trwałość kleju.`,
    },
    {
      id: 2,
      no: "KROK 2",
      text: `<span class="font-medium">Unikaj pocierania oczu, drapania lub szarpania rzęs, </span>ponieważ może to spowodować ich utratę.`,
    },
    {
      id: 3,
      no: "KROK 3",
      text: `<span class="font-medium">Zachowaj ostrożność w kontakcie z kosmetykami: </span>Stosuj delikatne kosmetyki do oczu, które nie zawierają tłustych składników i nie niszczą kleju.`,
    },
    {
      id: 4,
      no: "KROK 4",
      text: `<span class="font-medium">Stosuj delikatne kosmetyki do demakijażu twarzy. </span>Zadbaj, aby nie miały one w składzie olejków.`,
    },
    {
      id: 5,
      no: "KROK 5",
      text: `<span class="font-medium">Zadbaj o regularną korektę: </span>Aby utrzymać efekt stylizacji, konieczne jest regularne wykonanie korekty rzęs co kilka tygodni.`,
    },
    {
      id: 6,
      no: "KROK 6",
      text: `<span class="font-medium">Zrezygnuj z zalotki i tuszu do rzęs: </span>Po stylizacji rzęs nie ma potrzeby stosowania zalotki i tuszu do rzęs.`,
    },
  ],
  lashes_lamination: [
    {
      id: 1,
      no: "",
      text: "Po zabiegu laminacji rzęs warto przestrzegać pewnych zaleceń, aby utrzymać efekt i zachować piękne spojrzenie:",
    },
    {
      id: 2,
      no: "KROK 1",
      text: "Unikaj kontaktu rzęs z wodą przez co najmniej 24 godziny po zabiegu.",
    },
    {
      id: 3,
      no: "KROK 2",
      text: "Nie używaj tuszu do rzęs ani kosmetyków do oczu przez 24 godziny.",
    },
    {
      id: 4,
      no: "KROK 3",
      text: "Nie używaj zalotki do rzęs po laminacji, ponieważ może to uszkodzić 	efekt.",
    },
    {
      id: 5,
      no: "KROK 4",
      text: "Stosuj się do zaleceń kosmetyczki dotyczących pielęgnacji po zabiegu.",
    },
  ],
  eyebrow_lamination: [
    {
      id: 1,
      no: "",
      text: "Po zabiegu laminacji brwi warto przestrzegać pewnych zaleceń, aby utrzymać efekt i zapewnić zdrowie włosków i skóry:",
    },
    {
      id: 2,
      no: "KROK 1",
      text: "Unikaj kontaktu brwi z wodą przez co najmniej 24 godziny po zabiegu.",
    },
    {
      id: 3,
      no: "KROK 2",
      text: "Nie używaj żadnych produktów do stylizacji brwi przez kilka dni po zabiegu.",
    },
    {
      id: 4,
      no: "KROK 3",
      text: "Nie przeczesuj brwi ani nie próbuj zmieniać ich kształtu przez co najmniej 24 godziny.",
    },
    {
      id: 5,
      no: "KROK 4",
      text: "Pielęgnuj i nawilżaj okolicę zabiegu przy pomocy delikatnych kosmetyków.",
    },
  ],
  classic_henna: [
    {
      id: 1,
      no: "KROK 1",
      text: "Unikaj kontaktu brwi z wodą przez co najmniej 24 godziny po zabiegu",
    },
    {
      id: 2,
      no: "KROK 2",
      text: "Nie używaj żadnych produktów do stylizacji brwi przez kilka dni po zabiegu",
    },
    {
      id: 3,
      no: "KROK 3",
      text: "Nie nakładaj na brwi kremów ani nic tłustego. Dzięki temu henna utrzyma się zdecydowanie dłużej",
    },
  ],
  powder_henna: [
    {
      id: 1,
      no: "KROK 1",
      text: "Unikaj kontaktu brwi z wodą przez co najmniej 24 godziny po zabiegu",
    },
    {
      id: 2,
      no: "KROK 2",
      text: "Nie nakładaj na brwi żadnych kosmetyków (np. kremów). Będzie to powodować szybką utratę efektu",
    },
    {
      id: 3,
      no: "KROK 3",
      text: "Unikaj opalania i korzystania z sauny przez kilka dni po zabiegu",
    },
    {
      id: 4,
      no: "KROK 4",
      text: "Nie pocieraj, ani też nie przeczesuj włosków",
    },
  ],
  eyebrow_makeup: [
    {
      id: 1,
      no: "KROK 1",
      text: `Unikaj kontaktu brwi z wodą przez 4 dni od zabiegu`,
    },
    {
      id: 2,
      no: "KROK 2",
      text: `Nie opalaj się i nie korzystaj z sauny przez cały okres gojenia`,
    },
    {
      id: 3,
      no: "KROK 3",
      text: `Stosuj odpowiednie kremy i maści na wskazanie profesjonalisty, aby zapewnić prawidłową regenerację skóry`,
    },
    {
      id: 4,
      no: "KROK 4",
      text: `Nie zrywaj i nie drap strupków, które powstają podczas gojenia`,
    },
    {
      id: 5,
      no: "KROK 5",
      text: `Unikaj ostrych peelingów tuż po zabiegu`,
    },
  ],
  lip_makeup: [
    {
      id: 1,
      no: "",
      text: "Po zabiegu makijażu permanentnego ust warto przestrzegać pewnych zaleceń, aby utrzymać efekt i zapewnić zdrowie ust i skóry:",
    },
    {
      id: 2,
      no: "KROK 1",
      text: "Unikaj kontaktu ust z wodą przez co najmniej 24 godziny po zabiegu",
    },
    {
      id: 3,
      no: "KROK 2",
      text: "Unikaj jedzenia lub picia gorących i pikantnych potraw przez kilka dni po zabiegu",
    },
    {
      id: 4,
      no: "KROK 3",
      text: "Stosuj odpowiednie kremy i maści na wskazanie profesjonalisty, aby zapewnić prawidłową regenerację skóry ust",
    },
    {
      id: 5,
      no: "KROK 4",
      text: "Nie skubać, nie urywać skórek, które powstają w naturalnym procesie gojenia",
    },
    {
      id: 6,
      no: "KROK 5",
      text: "Unikaj ekspozycji na słońce oraz sauny do czasu zagojenia się rany",
    },
  ],
  eyeliner_makeup: [
    {
      id: 1,
      no: "",
      text: `Po zabiegu makijażu permanentnego kreski na powiece warto przestrzegać pewnych zaleceń, aby utrzymać efekt i zapewnić zdrowie skóry powiek:`,
    },
    {
      id: 2,
      no: "KROK 1",
      text: `Unikaj kontaktu oczu z wodą przez co najmniej 24 godziny po zabiegu`,
    },
    {
      id: 3,
      no: "KROK 2",
      text: `Po upływie 24 h delikatnie przemywaj skórę wokół oczu, osuszaj twarz delikatną chusteczką`,
    },
    {
      id: 4,
      no: "KROK 3",
      text: `Unikaj korzystania z basenu, sauny i innych miejsc, gdzie może wystąpić intensywna wilgoć przez kilka dni po zabiegu`,
    },
    {
      id: 5,
      no: "KROK 4",
      text: `Stosuj odpowiednie kremy i maści na wskazanie profesjonalisty, aby zapewnić prawidłową regenerację skóry powiek`,
    },
    {
      id: 6,
      no: "KROK 5",
      text: `Unikaj sauny oraz solarium przez cały okres gojenia`,
    },
    {
      id: 7,
      no: "KROK 6",
      text: `Zrezygnuj z makijażu oczu na okres rekonwalescencji`,
    },
  ],
  makeup_removal: [
    {
      id: 1,
      no: "",
      text: `Po zabiegu usuwania makijażu permanentnego należy przestrzegać zaleceń, które wesprą proces gojenia i minimalizują ryzyko powikłań:`,
    },
    {
      id: 2,
      no: "KROK 1",
      text: `<span class="font-medium">Unikaj ekspozycji na słońce</span>, a także wizyt w solarium: Skóra może być wrażliwa na promienie UV po zabiegu, dlatego ważne jest stosowanie kremów z filtrem przeciwsłonecznym.`,
    },
    {
      id: 3,
      no: "KROK 2",
      text: `<span class="font-medium">Unikaj basenu oraz sauny: </span>Chlor w basenie lub wysoka temperatura w saunie mogą wpływać negatywnie na proces gojenia.`,
    },
    {
      id: 4,
      no: "KROK 3",
      text: `<span class="font-medium">Zadbaj o pielęgnację skóry </span>poprzez odpowiednie kremy oraz maści.`,
    },
    {
      id: 5,
      no: "KROK 4",
      text: `<span class="font-medium">Pamiętaj, aby nie dłubać ani nie drapać miejsca zabiegowego. </span>Poczekaj cierpliwie na wygojenie.`,
    },
  ],
  detox_peel: [
    {
      id: 1,
      no: "KROK 1",
      text: `<span class="font-medium">Unikaj ekspozycji fragmentu pozabiegowego na słońce. </span>Unikaj bezpośredniego kontaktu z słońcem przez co najmniej kilka dni po zabiegu. Jeśli musisz wychodzić na zewnątrz, zawsze stosuj krem z filtrem o wysokim SPF.`,
    },
    {
      id: 2,
      no: "KROK 2",
      text: `<span class="font-medium">Unikaj aktywności fizycznej przez 24-48h. </span>Wysoka temperatura i nadmierny pot mogą podrażnić skórę.`,
    },
    {
      id: 3,
      no: "KROK 3",
      text: `<span class="font-medium">Zadbaj o odpowiednią pielęgnację. </span>Stosuj łagodne i nawilżające produkty do pielęgnacji skóry, które nie zawierają składników drażniących. Unikaj używania produktów z retinoidami lub kwasami przez kilka dni po zabiegu.`,
    },
    {
      id: 4,
      no: "KROK 4",
      text: `<span class="font-medium">Nie drap miejsca zabiegowego. </span>Złuszczająca się skóra po Detox Peel jest delikatna i może być podatna na uszkodzenia.`,
    },
    {
      id: 5,
      no: "KROK 5",
      text: `<span class="font-medium">Unikaj sauny oraz gorących kąpieli. </span>Wysoka temperatura może powodować podrażnienie skóry.`,
    },
    {
      id: 6,
      no: "KROK 6",
      text: `<span class="font-medium">Pamiętaj o nawilżeniu: </span>Regularne stosowanie nawilżającego kremu po zabiegu pomoże zachować odpowiednie nawilżenie skóry i wspomaga jej regenerację.`,
    },
    {
      id: 7,
      no: "KROK 7",
      text: `<span class="font-medium">Unikaj używek: </span>Alkohol i palenie mogą opóźnić proces gojenia się skóry. Dlatego warto ograniczyć ich spożycie przez kilka dni po zabiegu.`,
    },
  ],
  chemical_peeling: [
    {
      id: 1,
      no: "KROK 1",
      text: `Unikaj ekspozycji na słońce i stosuj krem z filtrem SPF`,
    },
    {
      id: 2,
      no: "KROK 2",
      text: `Wymień pościel na czystą`,
    },
    {
      id: 3,
      no: "KROK 3",
      text: `Stosuj nawilżające kremy, aby utrzymać odpowiednie nawilżenie skóry`,
    },
    {
      id: 4,
      no: "KROK 4",
      text: `Unikaj używania agresywnych produktów do pielęgnacji skóry przez kilka dni po zabiegu`,
    },
    {
      id: 5,
      no: "KROK 5",
      text: `Nie drap skóry ani nie usuwaj samodzielnie złuszczających się fragmentów skóry`,
    },
    {
      id: 6,
      no: "KROK 6",
      text: `Unikaj makijażu przez kilka dni po zabiegu`,
    },
  ],
  mesotherapy: [
    {
      id: 1,
      no: "",
      text: `Po zabiegu mezoterapii igłowej bardzo ważne jest odpowiednie dbanie o skórę, dlatego:`,
    },
    {
      id: 2,
      no: "KROK 1",
      text: `Unikaj ekspozycji na słońce`,
    },
    {
      id: 3,
      no: "KROK 2",
      text: `Stosuj kremy nawilżające oraz kremy ochronne`,
    },
    {
      id: 4,
      no: "KROK 3",
      text: `Zrezygnuj z alkoholu na minimum 24h`,
    },
    {
      id: 5,
      no: "KROK 4",
      text: `Powstrzymaj się od makijażu przez ok. 48h`,
    },
    {
      id: 6,
      no: "KROK 5",
      text: `Zrezygnuj z peelingów oraz zabiegów z użyciem kwasów`,
    },
  ],
  tissue_stimulator: [
    {
      id: 1,
      no: "KROK 1",
      text: `Unikaj ekspozycji na słońce`,
    },
    {
      id: 2,
      no: "KROK 2",
      text: `Unikaj masażu oraz nacisku na obszar poddany zabiegowi przez kilka dni`,
    },
    {
      id: 3,
      no: "KROK 3",
      text: `Ogranicz (a najlepiej zrezygnuj) z aktywności fizycznej przez kilka dni po zabiegu`,
    },
    {
      id: 4,
      no: "KROK 4",
      text: `Dbaj o higienę obszaru poddanego zabiegowi`,
    },
    {
      id: 5,
      no: "KROK 5",
      text: `Zrezygnuj używania z drażniących kosmetyków w okolicy zabiegowej`,
    },
  ],
  lip_modeling: [
    {
      id: 1,
      no: "KROK 1",
      text: `Unikaj malowania ust przez 2 dni po zabiegu`,
    },
    {
      id: 2,
      no: "KROK 2",
      text: `Unikaj aktywności fizycznej przez ok. 1-2 dni po zabiegu`,
    },
    {
      id: 3,
      no: "KROK 3",
      text: `Unikaj opalania oraz wizyt w saunie przez 2-3 dni`,
    },
    {
      id: 4,
      no: "KROK 4",
      text: `Nie przykładaj lodu w obszar zabiegowy`,
    },
    {
      id: 5,
      no: "KROK 5",
      text: `Cierpliwie poczekaj na samoistne gojenie ust`,
    },
    {
      id: 6,
      no: "KROK 6",
      text: `Aby wspomóc regenerację, możesz smarować usta maścią z arniki`,
    },
  ],
  lipolysis: [
    {
      id: 1,
      no: "KROK 1",
      text: `Unikaj aktywności fizycznej (kilka pierwszych dni po zabiegu)`,
    },
    {
      id: 2,
      no: "KROK 2",
      text: `Nie wystawiaj ciała na działanie promieni słonecznych`,
    },
    {
      id: 3,
      no: "KROK 3",
      text: `Zrezygnuj z sauny`,
    },
    {
      id: 4,
      no: "KROK 4",
      text: `Zrezygnuj z masażu ciała`,
    },
  ],
  botulinum_toxin: [
    {
      id: 1,
      no: "",
      text: "Po zabiegu toksyną botulinową ważne jest przestrzeganie zaleceń pozabiegowych:",
    },
    {
      id: 2,
      no: "KROK 1",
      text: `<span class="font-medium">Unikaj masażu oraz nacisku: </span>Nie należy masować lub naciskać obszarów poddanych zabiegowi przez kilka dni po procedurze.`,
    },
    {
      id: 3,
      no: "KROK 2",
      text: `<span class="font-medium">Unikaj leżenia na płasko: </span>Zaleca się nie leżeć płasko przez co najmniej 4 godziny po zabiegu, aby uniknąć rozprzestrzeniania się toksyny botulinowej. Nie pochylaj głowy przez ok. 4h od momentu zabiegu!`,
    },
    {
      id: 4,
      no: "KROK 3",
      text: `<span class="font-medium">Unikaj solarium oraz sauny!</span>`,
    },
    {
      id: 5,
      no: "KROK 4",
      text: `<span class="font-medium">Unikaj aktywności fizycznej: </span>W ciągu pierwszych kilku godzin po zabiegu warto unikać intensywnej aktywności fizycznej!`,
    },
  ],
};

export default {
  name: "PostTreatmentSteps",
  computed: {
    activeSection() {
      return this.$store.state.activeSection;
    },
    currentSteps() {
      return stepsMap[this.activeSection] || [];
    },
  },
  methods: {
    getHeadingText(section) {
      const headingTextMap = {
        lashes_styling: ": Jak dbać o przedłużone rzęsy?",
        lashes_lamination: " po laminacji rzęs",
        eyebrow_lamination: " po laminacji brwi",
        classic_henna: " po klasycznej hennie rzęs",
        powder_henna: " po pudrowej hennie brwi",
        eyebrow_makeup: " po makijażu permanentnym brwi",
        lip_makeup: " po makijażu permanentnym ust",
        eyeliner_makeup: " ",
        makeup_removal: " ",
        laser_remover: "",
        detox_peel: " po Detox Peel",
        chemical_peeling: " ",
        mesotherapy: " po mezoterapii igłowej",
        tissue_stimulator: " ",
        lip_modeling: " po modelowaniu ust",
        lipolysis: " ",
        botulinum_toxin: " ",
      };
      return headingTextMap[section] || "";
    },
  },
  setup() {
    return {
      stepsMap,
    };
  },
};
</script>

<style scoped></style>
